/** @jsxImportSource @emotion/react */

import Icon from "../../components/icon/icon";
import { appColors, borderRadius, flexbox, fullWidth } from "../../emotion/utils";
import Tags from "./components/tags";

import { css } from "./css";

export default function HorseCard(props) {
  const { data, onClick, stall } = props;

  // const getFirstImage = (images) => {
  //   const placeholderImage = 'https://placehold.co/600x400?text=Horse\nWise';

  //   if (images && images.length > 0) {
  //     const firstImage = images[0];
  //     return firstImage.filename;
  //   }
    
  //   return placeholderImage;
  // };

  const headerBlock = (
    <div css={css.cardHeader}>
      <h1>{data.name}</h1>
      <span>{stall.name}</span>
    </div>
  );

  const horseImg = data?.image[0];

  const imgBlock = horseImg
    ? <img src={horseImg?.link} alt={data?.name || ""} />
    : (
      <div css={css.stallCard().plc}>
        <Icon icon="Horse" />
      </div>
    );

  // const tagsBlock = <Tags />;

  return (
    <div onClick={onClick} css={css.stallCard()} className="stallcard">
      {headerBlock}
      {imgBlock}
      {/*{tagsBlock}*/}
    </div>
  );
}