/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TOP_LEVEL_PAGES } from "../constants";

import AuthContext from "../../context/auth-context";

import CustomButton from "../custom-button/custom-button";
//import { IconSignOut } from "../icons/icon-signout";
import { css } from "./css";
import IconSignOut from "../icons/icon-signout";
function Menu({ activePage, setActivePage, navOpen, setNavOpen, hideMenu }) {
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);

  return (
    <nav className={`main-menu ${navOpen ? "open" : ""}`}>
      <ul className="main-menu__list" css={css.NavButtons} >
        {!hideMenu && (
          <>
            {TOP_LEVEL_PAGES.map((page) => {
              return (
                <li
                  key={page.path}
                  onClick={() => {
                    setNavOpen(false);
                    navigate(`/${page.path}`);
                    setActivePage(page.path);
                  }}
                  className={activePage === page.path ? "active" : ""}
                >
                  {page.display}
                </li>
              );
            })}
          </>
        )}

        <li className="logout" onClick={logout}>
          <IconSignOut />
          {/* <CustomButton
            label="Log Out"
            color="white"
            type="outlined"
            sty={{
              lineHeight: "26px",
              paddingTop: 0,
              paddingLeft: 20,
              paddingBottom: 0,
              paddingRight: 20,
            }}
          /> */}
        </li>
      </ul>
    </nav>
  );
}

export default Menu;
