import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthLayout from "./layouts/auth-layout";
import AdminRouter from "./routes/admin";
import { NotFound } from "./pages/not-found/not-found";
import LoginRouter from "./routes/login";
import { ThemeProvider } from "@emotion/react";
import { useState } from "react";
import { appThemes } from "./emotion/utils";
import { ErrorBoundary } from "./components/error-boundary";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Marketing from "./pages/marketing/marketing";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import { Provider as ReduxProvider } from 'react-redux';
import { appStore } from './store/appStore';

const queryClient = new QueryClient();


function App() {
  const [thm, setThm] = useState("light");

  return (
    <ReduxProvider store={appStore}>
      <ThemeProvider theme={appThemes(thm)}>
        <ErrorBoundary>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route path="/" element={<Marketing />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route element={<AuthLayout />}>
                  {LoginRouter}
                  {AdminRouter}
                </Route>

                <Route path="*" element={<NotFound />} />

              </Routes>
            </QueryClientProvider>
          </BrowserRouter>{" "}
        </ErrorBoundary>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;