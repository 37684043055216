import { useTheme } from "@emotion/react";
import { Formik, Form } from "formik";
import Client from "../../client";
import FormTextInput from "../../components/form-text-input/form-text-input";
import { FormElement } from "../../components/form-element/form-element";
import { css } from "./css";
import { Button, theme, Grid, Typography, Input } from 'antd';
const { useBreakpoint } = Grid;

const initialValues = {
  email: ""
};

const { useToken } = theme;

const ForgotPassword = () => {

  const { token } = useToken();

  const screens = useBreakpoint();

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };

  const theme = useTheme();

  const onSubmit = async (values) => {
    try {
      const email = values['email'];

      await Client.post("/auth/forgot-password", { email });
      alert("Check your email for a link to reset you password!");
    } catch (err) {
      console.log(err);
      alert(
        "Error occured trying to send password reset email, the email was not sent",
      );
    }
  };

  return (
    <section style={styles.section}>
        <div style={styles.container}>
          <div style={styles.header}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form>
                  <h3>
                    If you have forgotten your password you can reset it here.
                  </h3>
                  <FormElement
                    element="input"
                    name="email"
                    label="Email*"
                    formik={formik}
                    labelPosition="top"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  
                  <Button
                      label="Send Password"
                      onClick={formik.handleSubmit}
                      type="primary"
                  >
                    Send New Password
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
    </section>
  );
}

export default ForgotPassword;
