/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { css } from './css'
import { appColors } from '../../emotion/utils'

import { Tooltip } from "primereact/tooltip";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import IconTooltip from "../icons/icon-tooltip";

export default function FormSelect(props) {
  const {
    formik,
    name,
    label,
    labelPosition,
    tooltipText,
    color,
    size,
    viewBox,
    icon,
    hidden,
    options,
    required,
    placeholder,
    isMulti, // Added isMulti prop
  } = props;

  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const handleChange = (e) => {
    formik.setFieldValue(name, e.value);
  };

  return (
    <div css={css.container}
      hidden={hidden}
      className={`form-group-item ${
        labelPosition === "top" ? "label-top" : ""
      }`}
    >
      {(label || icon) && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {label && (
            <label htmlFor={name}>
              {label} {required && <span style={{ color: '#A00D06' }}>*</span>}
            </label>
          )}
          {icon && (
            <span className="custom-icon">
              <IconTooltip
                id={`${name}-icon`}
                tooltipText={tooltipText}
                color={color}
                size={size}
                icon={icon}
                viewBox={viewBox}
              />
            </span>
          )}
        </div>
      )}
      {isMulti ? (
        <div css={css.container}>
            <MultiSelect
            name={name}
            value={formik?.values[name] || []}
            options={options}
            onChange={handleChange}
            onBlur={formik?.handleBlur}
            className="dropdown"
            placeholder={placeholder || "Select"}
            display="chip"
            />
        </div>
      ) : (
        <div css={css.container}>
            <Dropdown
            name={name}
            value={formik?.values[name] || ""}
            options={options}
            onChange={handleChange}
            onBlur={formik?.handleBlur}
            className="dropdown"
            placeholder={placeholder || "Select"}
            />
        </div>
      )}
      {formik?.errors[name] && formik?.touched[name] && (
        <div className="error-message">{formik.errors[name]}</div>
      )}
    </div>
  );
}