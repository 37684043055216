import React from "react";
import { Route } from "react-router-dom";
import Login from "../pages/login/login";
import Signup from "../pages/signup/signup";
import AcceptInvite from '../pages/accept-invite/accept-invite';
import ForgotPassword from "../pages/forgot-password/forgot-password";
import ResetPassword from "../pages/reset-password/reset-password";

import UnProtectedLayout from "../layouts/unprotected";

const LoginRouter = (
  <Route element={<UnProtectedLayout />}>
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/accept-invite" element={<AcceptInvite />} />
    <Route
      path="/reset-password"
      element={<ResetPassword></ResetPassword>}
    ></Route>
    <Route
      path="/forgot-password"
      element={<ForgotPassword></ForgotPassword>}
    ></Route>
  </Route>
);

export default LoginRouter;