/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import { css } from './css';

import defaultImage from '../../assets/loader.gif';

import PropTypes from 'prop-types';

const VideoPlayer = ({
  url, 
  status,
  setStatus,
  style,
  liveImage
}) => {

const hasLiveImage = liveImage?.img ? true : false;

const [loading, setLoading] = useState(true);

const ref = useRef();
const refDiv = useRef();
var player;

useEffect(() => {
  if (!url) {
    return;
  }

  setLoading(true);
  setStatus("Initializing live stream...");

  player = new window.JSMpeg.Player(url, {
    canvas: ref.current,
    autoplay: true,
    onVideoDecode: () => {
      setLoading(false)
      setStatus("Camera found, streaming...")
    }
  });

  // console.log('player..', player);

  return () => {
    if (player) {
      player.stop();
    }
  }
}, [url]);

const getLiveImage = !hasLiveImage ? (
  <img
    src={defaultImage}
    alt="loading"
    css={css.loader.imgDefault}
  />
) : (
  <img
    src={liveImage}
    alt="loading"
    css={css.loader.imgThumb}
  />
);

const loader = (
  <div css={css.loader}>
    {getLiveImage}
  </div>
);

return (
  <div css={css.container(false, loading)} style={style}>
    <div ref={refDiv}>
      {loading && loader}
      <canvas ref={ref} width="500" height="500"></canvas>
    </div>      
  </div>
);
};

VideoPlayer.props = {
  url: PropTypes.string.isRequired
};

export default VideoPlayer;