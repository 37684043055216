import { appFonts, flexbox, fontbox, fullWidth, marginAuto, posRelative } from "../../emotion/utils";

export const css = theme => ({
  backgroundColor: theme?.background?.primary,
  minHeight: "100vh",
  paddingTop: 32,

  "@media screen and (min-width: 576px)": {
    paddingTop: 64,
  },

  block: {
    ...marginAuto(),
    maxWidth: 360,
    width: "80%",

    "> form": {
      ...flexbox("column", "center", "flex-start", 32),
      ...posRelative(),

      "> *": {
        ...fullWidth(),
        textAlign: "center",
      },
    },
  },

  formBlock: (isResettingPassword) => ({
    ...flexbox("column", "center", "flex-start", 24),
    display: isResettingPassword ? "none" : "flex",

    "> *": {
      ...fullWidth(),
    },

    intro: (theme) => ({
      ...flexbox("column", "center", "center", 12),

      h1: {
        ...fontbox(appFonts.inter, 20, 600, 28),
        color: theme?.text?.primary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts.inter, 30, 600, 38),
        },
      },
    }),
  }),
});
