/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useRef } from "react";
import CustomDivTable from "../../components/custom-table/custom-div-table";
//import ShowAddNewUserModal from "./show-add-new-user-modal";
//import ShowArchiveUserModal from "./show-archive-user-modal";
import { useQuery } from "../../hooks";
import { Toast } from "primereact/toast";
import NavigationActionBar from "../../components/navigation-action-bar/navigation-action-bar";
import IconGo from "../../components/icons/icon-go";
import { Button } from "antd";
import AddUserModal from "../../components/modals/AddUserModal";
import EditHorseModal from "../../components/modals/EditHorseModal";
import CustomDivTableActions from "../../components/custom-table/custom-div-table-actions";
import ButtonIcon from "../../components/button-icon/button-icon";
import Icon from "../../components/icon/icon";
import { appColors } from "../../emotion/utils";
import { css } from "./css";
import { useNavigate } from "react-router-dom";
import ShowArchiveHorseModal from "./show-archive-horse-modal";
import ViewHorseModal from "./modal-view-horse";

export default function Horses() {
  const toastRef = useRef();
  const [showUserModal, setShowUserModal] = useState(false);

  const [archiveHorseModal, setArchiveHorseModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditHorseModal, setShowEditHorseModal] = useState(false);
  const [showViewHorseModal, setShowViewHorseModal] = useState(false);

  const activeUser = useRef(null);
  const activeHorse = useRef(null);

  const navigate = useNavigate();

  const theme = useTheme();

  const { data, refetch } = useQuery("/horses");

  // function editUser(data) {
  //   activeUser.current = data;
  //   setShowUserModal(true);
  // }

  const onEdit = (data) => {
    // console.log('data is..', data);
    activeHorse.current = data;
    setShowEditHorseModal(true);
  };

  const onDelete = (data) => {
    // console.log('data is..', data);
    activeHorse.current = data;
    setArchiveHorseModal(true);
  };

  const handleToUser = (data) => {
    activeUser.current = data;
    setShowEditHorseModal(true);
    // navigate(`/admin/user/${data.id}`);
  }

  const onView = (data) => {
    activeHorse.current = data;
    setShowViewHorseModal(true);
  }
  
  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) => `${data.name ? data.name : "--"}`,
      display: "Name",
    },
    {
      name: "ownedby",
      default: () => "",
      valFn: (data) => `${data.creator ? data.creator.first_name + ' ' + data.creator.last_name : "--"}`,
      display: "Owned By",
    },
    {
      name: "stallnumber",
      default: () => "",
      valFn: (data) => `${data?.stall?.name ? data.stall.name : "--"}`,
      display: "Stall Number",
    },
    {
      name: "notes",
      default: () => "",
      valFn: (data) => `${data.description ? data.description : "--"}`,
      display: "Notes",
    },
    {
      name: "actions",
      default: () => "",
      valFn: (data) => {
        return (
          <CustomDivTableActions>
            <ButtonIcon
              icon={<Icon icon="Trash" stroke={null} fill={appColors?.grays?.[510]} />}
              // onClick={() => onDelete(data)}
              onClick={() => onDelete(data)}
            />
            <ButtonIcon
              icon={<Icon icon="Edit" stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={() => onEdit(data)}
            />
            <ButtonIcon
              icon={<Icon icon="ChevronRight" stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={() => onView(data)}
            />
          </CustomDivTableActions>
        );
      },
    },
  ];

  const modals = (
    <>
      {archiveHorseModal && (
        <ShowArchiveHorseModal
          setShowModal={setArchiveHorseModal}
          horse={activeHorse.current}
          onSuccess={refetch}
        />
      )}
      {showAddUserModal && (
        <AddUserModal
          setShowModal={setShowAddUserModal}
          user={activeUser.current}
          onSuccess={refetch}
        />
      )}
      {showEditHorseModal && (
        <EditHorseModal
          setShowModal={setShowEditHorseModal}
          horse={activeHorse.current}
          onSuccess={refetch}
        />
      )}
      {showViewHorseModal && (
        <ViewHorseModal
          setShowModal={setShowViewHorseModal}
          horse={activeHorse.current}
        />
      )}
    </>
  );

  return (
    <div css={css}>
      <Toast ref={toastRef} position="top-center" />
      <NavigationActionBar refetch={refetch} />

      <CustomDivTable
        columns={columns}
        rows={data?.data}
        // selectionMode="single"
        // onSelect={(it) => handleToUser(it)}
        filterKeys={["name"]}
        showSearch={false}
      />

      {modals}
    </div>
  );
}