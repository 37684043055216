/** @jsxImportSource @emotion/react */

import { Form, Formik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from '@emotion/react';

import { acceptInviteSchema } from '../../schemas';
import Client from '../../client';

import { LoginLogo } from '../../components/icons/logo-login';
import { FormElement } from '../../components/form-element/form-element';
import Button from '../../components/button/button';

import { css } from './css';

export default function AcceptInvite() {
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const theme = useTheme();

  const token = params.get('token');
  const invitation = params.get('invitation');

  const initialValues = {
    invitation: Number(invitation),
    token,
    password: '',
    confirmPassword: ''
  };

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: 'accept-invite',
    mutationFn: async data => Client.post('/auth/accept-invite', data),
    onSuccess: values => {
      navigate('/login');
    },
    onError: (err) => {
      console.error(err)
    },
  });

  const header = <LoginLogo />;

  const intro = (
    <div css={css().formBlock()}>
      <div css={css().formBlock().intro(theme)}>
        <h1>Accept Invitation</h1>
      </div>
    </div>
  );

  return (
    <div css={css(theme)}>
      <div css={css().block}>
        <Formik
          initialValues={initialValues}
          validationSchema={acceptInviteSchema}
          onSubmit={submitRequest}
        >
          {(formik) => (
            <Form>
              {header}
              {intro}
              
              <FormElement
                element="input"
                elementType="password"
                id="password"
                name="password"
                label="Password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              
              <FormElement
                element="input"
                elementType="password"
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                value={formik.values.confirmPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />

              <Button
                type="submit"
                label="Accept"
                disabled={!formik.dirty || !formik.isValid || isLoading}
                onClick={formik.handleSubmit}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
