/** @jsxImportSource @emotion/react */

import React, { useContext, useState, useEffect, useCallback } from "react";
import { useMutation } from "../../hooks";
import Client from "../../client";
import * as Yup from "yup";
import FormTextarea from '../../components/form-textarea/form-textarea'
import Button from "../../components/button/button";
import { css } from "./css";
import { useAppDispatch, useAppSelector } from "../../shared/model";
import { changeCurrentHorse, selectedCurrentHorse } from "../../store/horseSlice";
import { changeReloadState } from "../../store/noteSlice";


export default function NoteEntryComponent(props) {

  const currentHorse = useAppSelector(selectedCurrentHorse);

  const [message, setMessage] = useState(null);

  const handleSubmit = async () => {
    console.log('on submit fired..');

    const payload = {
      note_content: message,
      horse_id: currentHorse
    };

    try {
      const response = await Client.post("/notes", payload);
      console.log('response is..', response);
      changeReloadState(true);
    }
    catch (exception) {
      console.error(exception);
      alert(
        "Error occured trying to create a note."
      );
    }
  };

  return (
    <>
      <div className="formInput" css={css.form}>
         <FormTextarea
          element="input"
          elementType="text"
          id="message"
          name="message"
          label="Add note..."
          placeholder="Add note..."
          rows="8"
          onChange={(e) => {
            console.log('on change note input field.');
            setMessage(e.target.value);
          }}
        />
      </div>
      <Button
        css={css.button}
        label="Save Note"
        onClick={handleSubmit}
        type="type"
      />
    </>
  );
};