/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { css } from './css'
import { appColors } from '../../emotion/utils'
import React, { useState, useEffect } from 'react'
import ModalButtonSet from '../modal-set-button/modal-set-button'
import FormTextInput from '../../components/form-text-input/form-text-input'
import FormTextarea from '../../components/form-textarea/form-textarea'
import FormSelect from '../../components/form-select/form-select-simplified'
import { useMutation } from '@tanstack/react-query'
import Client from '../../client'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import addHorseSchema from '../../schemas/addHorseSchema'
import { useQuery } from '../../hooks'

export default function AddHorseModal({
  setShowModal = () => {},
  open = true,
  onSuccess = () => {},
  horse,
}) {
  console.log('horse is..', horse);

  const initialValues = {
    name: horse?.name || '',
    assign_stall: horse?.stallId || '',
    description: horse?.description || '',
    ferrier_name: horse?.ferrier_name ||'',
    ferrier_phone: horse?.ferrier_phone || '',
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const [hasError, setHasError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(open)

  const handleCancel = () => {
    setIsModalOpen(false)
    setShowModal(false)
  }

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: 'add-horse',
    networkMode: 'always',
    mutationFn: async data => Client.put(`/horse/${horse.id}`, data),
    onSuccess: values => {
      setShowModal(false)
      onSuccess(values.body)
    },
    onError: () => {
      setHasError(true)
    },
  })

  const { data: stallData } = useQuery("/stalls");

  const stallOptions = stallData?.data?.map(stall => ({
    value: stall.id,
    label: stall.name,
  }));

  return (
    <Dialog
      header={
        <span css={css.dialogHeader} className="dialog-header">
          Edit a Horse
        </span>
      }
      visible={isModalOpen}
      style={{ width: '400px' }}
      onHide={handleCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await submitRequest(values)
          } catch (error) {
            console.error(error)
          } finally {
            setSubmitting(false)
          }
        }}
        validationSchema={Yup.object({
          name: Yup.string().label('Horse Name').required(),
          // stall: Yup.label('Stall').required(),
          ferrier_name: Yup.string().label("Ferrier Name").required(),
          ferrier_phone: Yup.string().label("Ferrier Phone").required(),
          description: Yup.string().label('Desription').required(),
        })}
      >
        {formik => (
          <Form>
            <FormTextInput
              id="name"
              name="name"
              label="Name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Enter your horse name"
              required
            />
            {formik.errors.horse_name && (
              <div css={css.feedback}>{formik.errors.name}</div>
            )}

            <FormSelect
              formik={formik}
              name="assign_stall"
              label="Assign Stall"
              labelPosition="top"
              required
              placeholder="Select stall"
              options={stallOptions}
              value={formik.values.assign_stall}
            />
            {formik.ertouchedssign && formik.errors.assign_stall && (
              <div css={css.feedback}>{formik.ertouchedssign && formik.errors.assign_stall}</div>
            )}

            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ferrier_name}
              id="ferrier_name"
              name="ferrier_name"
              label="Ferrier Name"
              placeholder="Enter Ferrier Name"
              required
            />
            {formik.errors.ferrier_name && (
              <div css={css.feedback}>{formik.errors.ferrier_name}</div>
            )}

            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ferrier_phone}
              name="ferrier_phone"
              label="Ferrier Phone"
              placeholder="Enter Ferrier Phone"
              required
            />
            {formik.errors.ferrier_phone && (
              <div css={css.feedback}>{formik.errors.ferrier_phone}</div>
            )}

            <FormTextarea
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              name="description"
              label="Description"
              labelPosition="top"
              placeholder="Enter horse notes here..."
            />
            {formik.errors.description && (
              <div css={css.feedback}>{formik.errors.description}</div>
            )}
            
            <ModalButtonSet
              onCancel={handleCancel}
              isSubmitting={formik.isSubmitting}
              submitButtonText="Save changes"
              submitButtonColor={appColors.greens.base}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
