import {
  appColors,
  appFonts,
  borderRadius,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  marginAuto,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posRelative,
  theme,
  margin,
} from '../../emotion/utils'

export const css = {
  container: {
    display: 'inline-flex',
    gap: '5px', // Adjust the gap value between radio button and label
    margin: '5px 10px 15px 0', // Adjust the margin value all elements in the radio group

    label: {
      ...fontbox(appFonts.inter, 14, 700, 24),
      color: appColors.grays[700],
      marginBottom: 8,
    },
    '& .multiselect': {
      ...fontbox(appFonts.inter, 14, 400, 24),
      ...borderRadius(4),
      border: '1px solid #E6E9EA',
      width: '100%',

      '&:focus-visible': {
        borderColor: appColors.grays[900],
      },
    },
  },
  dialogHeader: {
    ...fontbox(appFonts.inter, 18, 700, 16),
  },
  groupLabel: {
    ...fontbox(appFonts.inter, 14, 700, 24),
    color: appColors.grays[700],
    marginBottom: 8,
  },
}
