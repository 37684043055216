import React from "react";

import Input from "./elements/input";

export const FormElement = ({
  element = "input",
  elementType = element === "input" ? "text" : null,
  id,
  name,
  onBlur,
  onChange,
  value,
  label,
  placeholder,
}) => {
  const components = {
    input: Input,
  };

  const props = {
    data: [element, elementType],
    id,
    name,
    onBlur,
    onChange,
    value,
    label,
    placeholder,
  };

  const getFormElement = (type) => {
    const Component = components[type] || Input;

    return <Component {...props} />;
  };

  return <>{getFormElement(element.element)}</>;
};
