import {
    borderRadius,
    flexbox,
    fontbox,
    fullWidth,
    paddingX,
    paddingY,
    theme,
    themes,
  } from "../../emotion/utils";
  
  export const cssFormElements = {
    ...flexbox("column", "flex-start", "flex-start", 6),
  
    "> *, input": {
      ...fullWidth(),
    },
  
    label: {
      ...fontbox(theme.fonts.inter, 14, 500, 20),
      color: themes.light.colors.text.secondary,
      textAlign: "left",
    },
  
    input: {
      ...borderRadius(8),
      ...fontbox(theme.fonts.inter, 16, 400, 24),
      ...paddingX(14),
      ...paddingY(10),
      backgroundColor: themes.light.colors.background.primary,
      border: `1px solid ${themes.light.colors.border.primary}`,
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      color: themes.light.colors.placeholder,
    },
  };
  