import stepcomplete from "../../assets/step-complete.svg";
import {
	appColors,
	appFonts,
	fontbox,
    flexbox,
    margin,
    borderRadius
} from "../../emotion/utils";

export const css = {
    onboarding: {
        ...flexbox("column", "center", "center", 24),
        margin: "96px auto",
        // height: "100vh",
        width: "100%",

        padding: 24,
        backgroundColor: appColors.grays[0],
        '.formgroup': {
            width: "100%",
            ".subheader": {
                ...fontbox(appFonts.inter, 16, 400, 16),
                textAlign: "center",
                color: appColors.grays[700],
                margin: "0 auto 24px",

            },
        },
        ".stepstart": {
            maxWidth: 360,
            margin: "0 auto",
        },
        ".stepend": {
            maxWidth: "80%",
            maxWidth: "1200px",
            borderRadius: 8,
            margin: "0 auto 24px",
            border: "1px solid " + appColors.grays[100],
        },

        '.header':{
            ...fontbox(appFonts.inter, 30, 600, 30),
            color: appColors.grays[900],
            marginBottom: 24,
        },
       

    },

    onboardingButtons: {
        ...flexbox("row", "center", "center", 12),
        marginTop: -20,
        marginBottom: 24,
        width: "100%",
        justifyContent: "center",

        button: {
            ...fontbox(appFonts.inter, 16, 600, 16),
            width: 360,
            height: 48,
            borderRadius: 8,
            backgroundColor: appColors.greens.base,
            color: appColors.grays[0],
            border: "none",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",

            "&:hover": {
                backgroundColor: appColors.greens.dark,
                color: appColors.greens.textlight,
            }
        }
    },

    feedback: {
        ...fontbox(appFonts.inter, 12, 400, 12),
        color: appColors.reds.base,
        marginBottom: 8,
        marginTop: -20,
    },
    steps: {
        ...fontbox(appFonts.inter, 12, 400, 12),
        color: appColors.grays[700],
        width: 100,
        margin: "0 auto",
        marginBottom: 8,
        "& li:before": {
                display:"none",
            },
            "& .p-steps-title": {
                display: "none",
            },
            "& .p-disabled" : {
                opacity: 1,
            },
            "& .p-disabled a" : {
                textDecoration: "none",
            },
            "& .p-steps-current .p-steps-number": {
                color: 'transparent',
                backgroundColor: appColors.grays[0],
                border: "8px solid " + appColors.greens.base,
                width: 24,
                height: 24,
                minWidth: 24,
            },
            "& .p-disabled .p-steps-number": {
                color: 'transparent',
                backgroundColor: appColors.grays[100],
                border: "8px solid " + appColors.grays[200],
                width: 24,
                height: 24,
                minWidth: 24,
            },
            "& .p-disabled.highlight .p-steps-number": {
                color: 'transparent',
                backgroundImage: `url(${stepcomplete})`,
                //backgroundColor: appColors.grays[100],
                border: "0px solid " + appColors.reds.base,
                width: 24,
                height: 24,
                minWidth: 24,
            },

    }


};