import { flexbox, fullWidth, posAbsolute, posRelative, square } from "../../emotion/utils";

export const css = {
  cameraArea: {
    ...posRelative(),
    ...flexbox("row", "flex-start", "flex-start", 2, "wrap"),
    ...fullWidth(),
    paddingRight: 36,

    "> div": {
      ...posAbsolute(0, 0, null, null),
    },
  },

  settings: {
    ...flexbox("column", "center", "center"),
    ...square(36),
    cursor: "pointer",
  },
};
