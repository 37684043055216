/** @jsxImportSource @emotion/react */

import { css } from "./css";
import { Dialog } from 'primereact/dialog'
import UserCard from '../../components/user-card/user-card'
import Icon from '../../components/icon/icon';
import React from 'react';

export default function ViewHorseModal({
  setShowModal = () => {},
  open = true,
  horse,
}) {
  console.log('horse is..', horse);

  const handleCancel = () => {
    setShowModal(false)
  }

  const horseAvatar = horse?.images?.length > 0
    ? <img src={horse.images[0]?.link} alt={horse?.name} />
    : (
      <div css={css.dialog.defaultAvatar}>
        <Icon icon="Horse" />
      </div>
    );

  const avatar = horseAvatar;

  const stall = <div css={css.dialog.info.title}>{horse?.stall.name || "Stall Number"}</div>;

  const gender = <div>{horse?.gender || "Horse gender"}</div>;

  const title = horse?.role_name && <div css={css.dialog.info.title}>{horse?.role_name}</div>;
  
  const phone = horse?.phone && <div>{horse?.phone}</div>;
  // const phone = <div>{horse?.phone || "(123) 555-1745"}</div>;// this is placeholder to show data during development

  const email = horse?.email && <div>{horse?.email}</div>;

  const horseAge = horse && horse?.age && <div>{horse?.age}</div>;
  // const horseAge = <div>{horse?.age || "Gelding"}</div>;// this is placeholder to show data during development
  
  // const horseVetName = horse && horse?.vet_name && <div>Vet: {horse?.vet_name}</div>;
  const horseVetName = <div>Vet: {horse?.vet_name || "Vet Name"}</div>;// this is placeholder to show data during development
  
  // const horseVetPhone = horse && horse?.vet_phone && <div>Vet Phone: {horse?.vet_phone}</div>;
  const horseVetPhone = <div>Vet Phone: {horse?.vet_phone || "(423) 555-1739)"}</div>;// this is placeholder to show data during development
  
  // const horseFerrierName = horse && horse?.ferrier_name && <div>Ferrier: {horse?.ferrier_name}</div>;
  const horseFerrierName = <div>Ferrier: {horse?.ferrier_name || "Ferrier Name"}</div>;// this is placeholder to show data during development
  
  // const horseFerrierPhone = horse && horse?.ferrier_phone && <div>Ferrier Phone: {horse?.ferrier_phone}</div>;
  const horseFerrierPhone = <div>Ferrier Phone: {horse?.ferrier_phone || "Ferrier Phone"}</div>;// this is placeholder to show data during development

  const horseNotes = horse && horse?.description && (
    <div css={css.dialog.info.notes}>
      <div>Notes:</div>
      {horse?.description}
    </div>
  );

  const elements = [stall, gender, horseAge, horseVetName, horseVetPhone, horseFerrierName, horseFerrierPhone];

  const left = (
    <div css={css.dialog.info.l}>
      {elements.map((el, index) => (
        <React.Fragment key={index}>{el}</React.Fragment>
      ))}
    </div>
  );

  const right = (
    <div css={css.dialog.info.r}>
      <div css={css.dialog.info.r.avatar}>
        {avatar}
      </div>
    </div>
  );

  const dialogContent = (
    <div css={css.dialog.info}>
      {left}
      {right}
      {horseNotes}
    </div>
  );

  return (
    <Dialog
      header={
        <span css={css.dialog.dialogHeader} className="dialog-header">
          {horse?.name}
        </span>
      }
      visible={open}
      style={{ width: 518 }}
      onHide={handleCancel}
    >
      {dialogContent}
    </Dialog>
  )
}
