/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import { changeCurrentCamera, selectedCurrentCamera } from "../../store/cameraSlice";
import { useAppDispatch, useAppSelector } from "../../shared/model";
import VideoPlayer from "../video-player/video-player";
import { css } from "./css";
import Icon from "../icon/icon";


export default function VideoComponent(props) {

  const {
    currentCamera
  } = props;

  const [cameraToShow, setCameraToShow] = useState({});

  const selectedCamera = useAppSelector(selectedCurrentCamera);

  const initStatus = "Real times snapshot, loading camera stream";

  const [status, setStatus] = useState(initStatus);
  const [wsUrl, setWsUrl] = useState(null);

  useEffect(() => {
    setCameraToShow(selectedCamera);
  }, [selectedCamera]);

  useEffect(() => {
    // console.log('camera..', currentCamera);
    setCameraToShow(currentCamera);
  }, [currentCamera]);

  useEffect(() => {
    if (cameraToShow && cameraToShow.id) {
      const wsUrl = window.location.hostname === 'localhost'
      ? 'ws://localhost:3001'
      : `wss://${window.location.host}`
      const currentCameraLink = `${wsUrl}/api/camera/${cameraToShow?.id}`;
      setWsUrl(currentCameraLink);
    }
  }, [cameraToShow]);

  console.log("current camera..", currentCamera);

  const contents = currentCamera && wsUrl && wsUrl.length > 0
    ? (
      <VideoPlayer
        url={currentCamera?.id ? `${wsUrl}` : null} 
        status={status}
        setStatus={setStatus}
        id={currentCamera?.id}
      />
    )
    : (
      <div css={css.videocard.loading}>
        <Icon icon="Video" fill="#9F9F9F" />
        <p>loading...</p>
      </div>
    );

  return (
    <div css={css.videocard}>
      {contents}
    </div>
  );
}