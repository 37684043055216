/** @jsxImportSource @emotion/react */
import { css } from "./css";
import React, { useMemo } from "react";
import NoteListingComponent from "../../components/note-listing-component/note-listing-component";
import VideoComponent from "../../components/video-component/video-component";
import CameraSelectorComponent from "../../components/camera-selector-component/camera-selector-component";
import NoteEntryComponent from "../../components/note-entry-component/note-entry-component";
import RequestVetButton from "../../components/request-vet-button/request-vet-button";
import { selectedCurrentHorse } from "../../store/horseSlice";
import { selectedCurrentCamera } from "../../store/cameraSlice";
import { useAppSelector } from "../../shared/model";
import { useQuery } from "../../hooks/use-query";

import { ENDPOINT } from "../../client";

export default function HorseSection(props) {
  const currentHorse = useAppSelector(selectedCurrentHorse);
  const currentCamera = useAppSelector(selectedCurrentCamera);

  const camerasUri = useMemo(() => {
    const url = new URL("/cameras", ENDPOINT);
    url.searchParams.append(
      "horse_id",
      currentHorse
    );
    return url.toString().replace(ENDPOINT, "");
  }, []);

  const notesUri = useMemo(() => {
    const url = new URL("/notes", ENDPOINT);
    url.searchParams.append(
      "horse_id",
      currentHorse?.id
    );
    return url.toString().replace(ENDPOINT, "");
  }, [currentHorse]);

  const { data: notesData } = useQuery(notesUri);
  const { data } = useQuery(camerasUri);

  const getNotes = (horseId) => {
    let filteredNotes = [];

    notesData?.data?.map((row) => {
      if (row?.horseId === horseId) {
        filteredNotes.push({
          ...row,
          name: row.name,
          id: row.id,
        });
      }    
    });

    return <NoteListingComponent css={css.stallNotes} notes={filteredNotes} />;
  };

  const videoBlock = (
    <div css={css.videoBlock} className="videoContentArea">
      {/* {currentCamera && <VideoComponent currentCamera={currentCamera} />} */}
      <VideoComponent currentCamera={currentCamera} />
      
      <CameraSelectorComponent />
    </div>
  );

  const headerBlock = (
    <div css={css.stallCardHeader} className="horseDescriptorsArea">
      <h1>{currentHorse?.name}</h1>
    </div>
  );

  const notesblock = (
    <div css={css.stallNoteSection} className="horseNotesArea">
      <h3>Notes</h3>
      {getNotes(currentHorse?.id)}
    </div>
  );

  const addNoteForm = (
    <div className="stallNoteEntry" css={css.stallNoteEntry}>
      <NoteEntryComponent className="stallNoteEntry" />
    </div>
  );

  const btnRequestVet = <RequestVetButton />;

  const actions = [btnRequestVet];

  const actionsBlock = (
    <div css={css.tray.actions} className="requestVetButton">
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </div>
  );

  return (
    <div css={css.tray.container}>
      {videoBlock}
      {headerBlock}
      {notesblock}
      {addNoteForm}
      {actionsBlock}
    </div>
  );
}