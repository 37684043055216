
/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { appColors } from '../../emotion/utils'
import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ModalButtonSet from '../modal-set-button/modal-set-button'
import { css } from './css';

export default function DeleteClipModal({ isOpen, onClose }) {
const handleCancel = () => {
    console.log('Delete Clip Cancelled');
    onClose();
    };

    const handleSubmit = () => {
    console.log('Delete Clip Confirmed');
    onClose();
    };
    

  return (
    <Dialog
    header={
        <span css={css.dialogHeader} className="dialog-header">
          Delete Clip
        </span>
      }
      visible={isOpen}
      style={{ width: '400px' }}
      onHide={onClose}
    >
   
      <div>
        <p css={css.subheader}>Are you sure you want to delete this clip?</p>
        <ModalButtonSet
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              submitButtonText="Delete"
              submitButtonColor={appColors.reds.base}
            />
      </div>
    </Dialog>
  );
}