import { Button, Flex } from 'antd';
import Client from "../../../../client";

const TakeClipButton = () => {

  const onClickRequest = async () => {
    try {
      const response = await Client.post("/clips/take-clip");
    }
    catch (exception) {
      alert(
        "Error occured trying to take a clip.",
      );
    }
  };

  return (
    <>
      <Button onClick={onClickRequest} type="primary">Take Clip</Button>
    </>
  );
};

export default TakeClipButton;