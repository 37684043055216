import { appColors, appFonts, borderRadius, flexbox, fontbox, fullWidth, marginAuto, paddingXY, square } from "../../emotion/utils";

export const css = {
  ...flexbox("column", "flex-start", "flex-start", 12),
  ...fullWidth(),
  ...marginAuto(),
  maxWidth: "94%",
  paddingTop: 10,
  paddingBottom: 35,

  ".tableContain": {
    ...fullWidth(),
  },

  dialog: {
    // ...flexbox("column", "center", "flex-start", 20),
    // ...fullWidth(),

    info: {
      ...flexbox("row", "flex-start", "space-between", 12, "wrap"),
      ...fontbox(appFonts?.inter, 14, 500, 14 * 1.44),
      ...fullWidth(),
      // ...paddingXY(24),
      // backgroundColor: appColors?.grays?.[0],
      // boxShadow: "0px 4px 16px 0px rgba(1, 32, 78, 0.08)",
      color: appColors?.grays?.[900],
      // maxWidth: 518,

      l: {
        ...flexbox("column", "flex-start", "flex-start", 8),
      },

      name: {
        ...fontbox(appFonts?.inter, 20, 700, 20 * 1.44),
      },

      title: {
        ...fontbox(appFonts?.inter, 16, 400, 16 * 1.44),
        textTransform: "capitalize",
      },

      notes: {
        ...fullWidth(),

        "> div": {
          ...fontbox(appFonts?.inter, 16, 700, 16 * 1.44),
        },
      },

      r: {
        width: 96,

        "img": {
          ...borderRadius("50%"),
          ...square(96),
          objectFit: "cover",
        },
      },
    },

    defaultAvatar: {
      ...borderRadius("50%"),
      ...flexbox("column", "center", "center"),
      ...square(96),
      backgroundColor: appColors?.grays?.[50],
    },
  },
};
