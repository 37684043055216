/**@jsxImportSource @emotion/react */

import React, { useState, useMemo, useEffect } from "react";
import { css } from "./css";
import Icon from "../icon/icon";

export default function UserCard({
  data,
  horse,
}) {

  const [firstVet, setFirstVet] = useState(null);

  console.log("Data is..", data);

  useEffect(() => {
    console.log("data is..", data);
    if (data) {
      if ("horse_to_vet" in data) {
        const vets = data["horse_to_vet"];
        if (vets[0]) {
          const firstVet = vets[0];
          setFirstVet(firstVet.vet);
        }
      }
    }
  }, [data]);

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  }

  const userAvatar = data?.avater
    ? <img src={data.avatar} alt={`${data?.first_name} ${data?.last_name}`} />
    : (
      <div css={css.defaultAvatar}>
        <Icon icon="User" />
      </div>
    );

  const horseAvatar = data?.images?.length > 0
    ? <img src={data.images[0]?.link} alt={data?.name} />
    : (
      <div css={css.defaultAvatar}>
        <Icon icon="Horse" />
      </div>
    );

  const avatar = !horse
    ? userAvatar
    : horseAvatar;

  const title = data?.role_name && <div css={css.info.title}>{data?.role_name}</div>;

  const fullName = !horse
    ? `${data?.first_name} ${data?.last_name}`
    : data?.name;
  console.log('horse is..', data);

  const name = (
    <div css={css.info.nameTitle}>
      <div css={css.info.name}>{fullName}</div>
    </div>
  );

  console.log('first vet..', firstVet);
  
  // const phone = data?.phone && <div>{data?.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</div>;
  const phone = <div>{data?.phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</div>;

  const email = data?.email && <div>{data?.email}</div>;

  // const horseAge = horse && data?.age && <div>{data?.age}</div>;
  const horseAge = horse && <div css={css.info.withLabel}><span>Age: </span>{data?.age}</div>;
  
  // const horseVetName = horse && data?.vet_name && <div>{data?.vet_name}</div>;
  const horseVetName = horse && <div css={css.info.withLabel}><span>Vet name: </span>{firstVet?.first_name} {firstVet?.last_name}</div>;
  
  const horseFerrierName = horse && <div css={css.info.withLabel}><span>Ferrier name: </span>{data?.ferrier_name || ""}</div>;
  
  const horseFerrierPhone = horse && <div css={css.info.withLabel}><span>Ferrier phone: </span>{data?.ferrier_phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || ""}</div>;

  const horseNotes = horse && data?.description && (
    <div css={css.info.notes}>
      <div>Notes:</div>
      {data?.description}
    </div>
  );

  const elements = !horse
    ? [name, title, phone, email]
    : [name, horseAge, horseVetName, horseFerrierName, horseFerrierPhone];

  const left = (
    <div css={css.info.l}>
      {elements.map((el, index) => (
        <React.Fragment key={index}>{el}</React.Fragment>
      ))}
    </div>
  );

  const right = (
    <div css={css.info.r}>
      <div css={css.info.r.avatar}>
        {avatar}
      </div>
    </div>
  );

  return (
    <div css={css}>
      {left}
      {right}
      {horseNotes}
    </div>
  );
};
