import { object, string, number, date, InferType } from 'yup';

const signupSchema = object({
  first_name: string().label("First Name").required(),
  last_name: string().label("Last Name").required(),
  email: string().label("Email").required(),
  password: string().label("Password").required(),
  password_confirm: string().label("Password Confirmation").required()
});

export default signupSchema;