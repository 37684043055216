/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import { css } from './css';
import { appColors } from '../../emotion/utils';

import {
  borderRadius,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
  theme,
} from "../../emotion/utils";

const FormTextarea = ({
  formik,
  name,
  onInput,
  readOnly,
  style,
  testChange,
  value,
  label,
  placeholder,
  rows,
  onChange,
}) => {
  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const textareaProps = {
    css: css.textarea,
    className: errorClass,
    style: style,
    id: name,
    name,
    onBlur: formik?.handleBlur,
    onChange: onChange || formik?.handleChange,
    onInput: onInput,
    readOnly,
    value: value,
    placeholder,
    rows: rows,
  };

  return (
    <div css={css.container}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea {...textareaProps} />
    </div>
  );
};

export default FormTextarea;