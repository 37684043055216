/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom';

import StallCard from './stall-card'

import { css } from './css'
import CustomDivTable from '../../components/custom-table/custom-div-table'

export default function StallSection(props) {
  const {
    data = [],
    activeBtnGroup
  } = props;

  const navigate = useNavigate();

  const tableCols = [
    { label: 'name' },
  ];

  const stallData = data?.sort((a, b) => a.name < b.name ? -1 : 1);

  const stallsBlock = activeBtnGroup === 'cards'
    ? stallData.map(datum => (
      <StallCard
        key={datum.id}
        data={datum}
        onClick={() => navigate(`/admin/stall/${datum.id}`)}
      />
    ))
    : (
      <CustomDivTable
        columns={tableCols}
        rows={stallData}
        count={stallData.length}
        onSelect={(r) => navigate(r)}
      />
    );

  return (
    <div
      css={css.cardSection}
    >
      {stallsBlock}
    </div>
  )
}
