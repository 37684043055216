import { display, height } from '@mui/system'
import { keyframes } from '@emotion/react'
import {
  appColors,
  appFonts,
  borderRadius,
  darken,
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  padding,
  posAbsolute,
  posRelative,
  square,
  themes,
  transition,
} from '../../../../emotion/utils'
const fadeOutAndMove = keyframes`
  0% {
    opacity: 0;
    margin-right: 0;
  }
  50% {
    opacity: 0;
    //margin-right: 600px;
  }
  100% {
    opacity: 1;
    //margin-right: 600px;
  }
`

export const css = {


  clipCard: theme => ({
    border: '1px solid #F6F6F6',
    borderRadius: '8px',
    padding: '16px',
    width: 'calc(33% - 16px)', // 3 cards across with some spacing
    boxShadow: '0px 4px 8px 0px #19354214',
    margin: '8px', // Adjust margin as needed
    backgroundColor: appColors.grays[0],

    img: {
      width: '100%',
      height: 'auto',
    },

    // Media queries for responsiveness
    '@media (max-width: 1200px)': {
      width: 'calc(33.33% - 16px)', // 3 cards across
    },
    '@media (max-width: 900px)': {
      width: 'calc(50% - 16px)', // 2 cards across
    },
    '@media (max-width: 600px)': {
      width: 'calc(100% - 16px)', // 1 card across
    },
    '.tray-open &': {
      width: 'calc(50% - 16px)', // Adjusted width when tray-open
      animation: `${fadeOutAndMove} 0.5s ease-in-out forwards`,
      '@media (max-width: 1000px)': {
        width: 'calc(100% - 16px)', // 2 cards across
      },
    },
  }),
  cardHeader: theme => ({
    ...flexbox('row', 'center', 'space-between', 12),
    padding: '24px 0px 24px 0px',

    h1: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },
    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  }),
  
 
}

