import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

const LOGIN_PATH = "/login";

function ProtectedLayout() {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  if (!loggedInUser || typeof loggedInUser !== "object") {
    return <Navigate to={LOGIN_PATH} replace></Navigate>;
  }

  return <Outlet />;
}

export default ProtectedLayout;