/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";

import { css } from "./css";

//import css from "./header.module.scss";
import MobTrigger from "./header-trigger";
import { useState } from "react";
import Menu from "../menu/menu";

import { NavLogo } from "../icons/logo";

export default function Header({
  activePage,
  setActivePage,
  hideMenu = false,
}) {
  const [navOpen, setNavOpen] = useState(false);

  const logo = (
    <Link to="/" className="logo">
      <div css={css.logoText}>
        <NavLogo />
      </div>
    </Link>
  );

  return (
    <header css={css.header}>
      <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} />

      {logo}

      <Menu
        activePage={activePage}
        setActivePage={setActivePage}
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        hideMenu={hideMenu}
      />
    </header>
  );
}
