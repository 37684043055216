/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { appColors } from "../../emotion/utils";

const svgHoverStyle = css({
  '&:hover path': {
    fill: appColors.reds.secondary,
  },
});

const IconSignOut = ({
  fill = appColors.greens.textlight,
  style,
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      css={svgHoverStyle}
    >
      <g id="icon/signout">
        <path
          id="Vector"
          d="M15 27C15 27.2652 14.8946 27.5196 14.7071 27.7071C14.5196 27.8946 14.2652 28 14 28H6C5.73478 28 5.48043 27.8946 5.29289 27.7071C5.10536 27.5196 5 27.2652 5 27V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H14C14.2652 4 14.5196 4.10536 14.7071 4.29289C14.8946 4.48043 15 4.73478 15 5C15 5.26522 14.8946 5.51957 14.7071 5.70711C14.5196 5.89464 14.2652 6 14 6H7V26H14C14.2652 26 14.5196 26.1054 14.7071 26.2929C14.8946 26.4804 15 26.7348 15 27ZM28.7075 15.2925L23.7075 10.2925C23.5199 10.1049 23.2654 9.99944 23 9.99944C22.7346 9.99944 22.4801 10.1049 22.2925 10.2925C22.1049 10.4801 21.9994 10.7346 21.9994 11C21.9994 11.2654 22.1049 11.5199 22.2925 11.7075L25.5863 15H14C13.7348 15 13.4804 15.1054 13.2929 15.2929C13.1054 15.4804 13 15.7348 13 16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17H25.5863L22.2925 20.2925C22.1049 20.4801 21.9994 20.7346 21.9994 21C21.9994 21.2654 22.1049 21.5199 22.2925 21.7075C22.4801 21.8951 22.7346 22.0006 23 22.0006C23.2654 22.0006 23.5199 21.8951 23.7075 21.7075L28.7075 16.7075C28.8005 16.6146 28.8742 16.5043 28.9246 16.3829C28.9749 16.2615 29.0008 16.1314 29.0008 16C29.0008 15.8686 28.9749 15.7385 28.9246 15.6171C28.8742 15.4957 28.8005 15.3854 28.7075 15.2925Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconSignOut;