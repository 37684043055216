import { display, height } from '@mui/system'
import { keyframes } from '@emotion/react'
import {
  appColors,
  appFonts,
  borderBottomLeftRadius,
  borderRadius,
  darken,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  marginAuto,
  marginY,
  padding,
  paddingX,
  paddingY,
  posAbsolute,
  posFixed,
  posRelative,
  square,
  themes,
  transition,
} from '../../emotion/utils'
const fadeOutAndMove = keyframes`
  0% {
    opacity: 0;
    margin-right: 0;
  }
  50% {
    opacity: 0;
    //margin-right: 600px;
  }
  100% {
    opacity: 1;
    //margin-right: 600px;
  }
`

export const css = {
  cardSection: theme => ({
    ...flexbox("row", "flex-start", "flex-start", 24, "wrap"),
    padding: '32px',
    transition: 'margin-right 0.25s ease-in-out',
    '&.tray-open': {
      marginRight: '600px',
    },
  }),

  tray: {
    ...fullHeight(),
    ...paddingX(16),
    ...posFixed(0, 0, null, null),
    ...transition('transform 0.3s ease-in-out'),
    backgroundColor: appColors?.grays?.[50],
    paddingTop: 78,
    paddingBottom: 30,
    width: 600,
    transform: "translateX(100%)",

    "&.open": {
      transform: "translateX(0)",      
    },

    closeButton: {
      ...borderBottomLeftRadius(4),
      ...flexbox("column", "center", "center"),
      ...posAbsolute(60, 0, null, null, 100),
      ...square(50),
      background: appColors?.reds?.destructive,
      border: 'none',
      cursor: 'pointer',
      zIndex: 1000,
    },

    // stallCardSection: {
    //   background: appColors.grays[0], 
    //   padding: '16px 16px 40px',
    //   height: '90vh',
    //   border: '1px solid #E6E9EA',
    //   borderRadius: '8px',
    //   overflowY: 'auto',
    //   overflowX: 'hidden',
    // },
    container: {
      ...flexbox("column", "flex-start", "flex-start", 8),
      background: appColors.grays[0], 
      padding: '16px 16px 40px',
      height: '90vh',
      border: '1px solid #E6E9EA',
      borderRadius: '8px',
      overflowY: 'auto',
      overflowX: 'hidden',

      "> *": {
        ...fullWidth(),
      },
    },

    actions: {

    },
  },

  videoBlock: {
    ...flexbox("column", "flex-start", "flex-start", 8),
  },

  stallCard: theme => ({
    ...flexbox("column", "center", "center", 16),
    border: '1px solid #F6F6F6',
    borderRadius: '8px',
    padding: '16px',
    width: 'calc(25% - 18px)', // 4 cards across with some spacing
    boxShadow: '0px 4px 8px 0px rgba(25, 53, 66, 0.08)',
    // margin: '8px', // Adjust margin as needed

    img: {
      ...borderRadius(4),
      display: "block",
      width: '100%',
      height: 'auto',
    },

    plc: {
      ...borderRadius(4),
      ...flexbox("column", "center", "center"),
      ...fullWidth(),
      backgroundColor: appColors?.grays?.[50],
      minHeight: 150,
    },

    // Media queries for responsiveness
    '@media (max-width: 1200px)': {
      width: 'calc(33.33% - 16px)', // 3 cards across
    },
    '@media (max-width: 900px)': {
      width: 'calc(50% - 16px)', // 2 cards across
    },
    '@media (max-width: 600px)': {
      width: 'calc(100% - 16px)', // 1 card across
    },
    '.tray-open &': {
      width: 'calc(50% - 16px)', // Adjusted width when tray-open
      animation: `${fadeOutAndMove} 0.5s ease-in-out forwards`,
      '@media (max-width: 1000px)': {
        width: 'calc(100% - 16px)', // 2 cards across
      },
    },
  }),
  cardHeader: theme => ({
    ...flexbox('row', 'center', 'space-between', 12),
    ...fullWidth(),

    h1: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },
    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  }),

  stallCardHeader: {
    ...flexbox('row', 'center', 'space-between', 12),
    ...paddingY(16),
    // padding: '24px 0px 24px 0px',

    h1: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },

    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  },

  stallNoteSection: {
    ...flexbox('column', 'start', 'space-between', 12),
    padding: '0px 0px 8px 0px',
    width: '100%',

    h3: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },
    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  },
  
  stallNotes: theme => ({
    width: '100%',
  }),
  stallNoteEntry: theme => ({
    width: '100%',
  }),
}

