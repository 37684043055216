/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import React, { useContext, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Toast } from 'primereact/toast'
import AuthContext from '../../context/auth-context'
import FormTextInput from '../../components/form-text-input/form-text-input'
import resetPasswordSchema from '../../schemas/resetPasswordSchema'
import Client from '../../client'
import { useMutation } from '@tanstack/react-query'
import Button from '../../components/button/button'
import { css } from './css'
import { LoginLogo } from '../../components/icons/logo-login'

function ResetPassword(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  let uid = searchParams.get('userId')
  let token = searchParams.get('token')
  console.log(uid)

  const initialValues = {
    confirmPassword: '',
    password: '',
    userId: uid,
    token,
  }

  const toastRef = useRef()
  const { login } = useContext(AuthContext)
  const [hasError, setHasError] = useState(false)

  const { mutateAsync: onSubmit, isLoading } = useMutation({
    mutationKey: 'reset_password',
    mutationFn: async data => Client.post('/auth/reset-password', data),
    onSuccess: data => {
      alert('Congratulations, your password has been changed!')
      login(data)
    },
    onError: error => {
      toastRef.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.toString(),
        life: 13000,
      })
      setHasError(true)
    },
  })

  const theme = useTheme()
  const header = <LoginLogo />

  return (
    <>
      <div className="container" css={css.container(theme)}>
        <div css={css.formBlock().intro}>
          <Toast ref={toastRef} position="top-center" />
          <LoginLogo />
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              await onSubmit(values)
              setSubmitting(false)
            }}
            validationSchema={resetPasswordSchema}
          >
            {formik => (
              <Form css={css.form}>
                <div css={css.formBlock().intro}>
                  <h1>Password Reset</h1>
                  <p>Enter your new password below.</p>
                </div>
                <div css={css.formBlock().elements}>
                  <FormTextInput
                    name="password"
                    label="Password"
                    formik={formik}
                    labelPosition="top"
                    type="password"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <span css={css.errormsg} className="form-error-msg">
                      {formik.errors.password}
                    </span>
                  )}
                  <FormTextInput
                    name="confirmPassword"
                    label="Confirm Password"
                    formik={formik}
                    labelPosition="top"
                    type="password"
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <span css={css.errormsg}  className="form-error-msg">
                        {formik.errors.confirmPassword}
                      </span>
                    )}
                  <Button
                    label="Reset Password"
                    onClick={formik.handleSubmit}
                    loading={isLoading}
                    disabled={
                      isLoading ||
                      Object.values(formik.errors).length ||
                      hasError
                    }
                    type="submit"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ResetPassword