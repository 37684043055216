import * as Yup from 'yup';
import { debounce } from 'lodash';

import Client from '../client';

const duplicateTest = (field) => debounce(async function(value) {
    try {
      const res = await Client.get(`/users?${field}=${value}`);
      return !res.data.length;
    } catch (err) {
      console.error(err);
      return true;
    }
  }
, 250);

const addUserSchema = Yup.object({
  username: Yup.string().required('Username is required')
    .test(
      'duplicate-username',
      () => 'Username is already taken',
      duplicateTest('username')
    ),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required')
    .test(
      'duplicate-email',
      () => 'Email is already taken',
      duplicateTest('email')
    ),
  phone: Yup.string().required('Phone is required'),
  role: Yup.string().required('Role is required'),
  assign_horse: Yup.array().min(1, 'At least one horse must be assigned'),
});

export default addUserSchema;