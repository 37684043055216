import {
	appColors,
	appFonts,
	fontbox,
} from "../../emotion/utils";

export const css = {

    feedback: {
        ...fontbox(appFonts.inter, 12, 400, 12),
        color: appColors.reds.base,
        marginBottom: 8,
        marginTop: -20,
    },
    subheader: {
        ...fontbox(appFonts.inter, 16, 400, 24),
        color: appColors.grays[700],
        marginBottom: 8,
    },

};