import { combineReducers } from '@reduxjs/toolkit';
import { horseReducer } from "./horseSlice";
import { cameraReducer } from "./cameraSlice";
import { barnSetupReducer } from './barnSetupSlice';
import { horseListReducer } from './horseListSlice';
import { userListReducer } from './userListSlice';
import { clipReducer } from './clipSlice';
import { noteReducer } from './noteSlice';


export const rootReducer = combineReducers({
  horse: horseReducer,
  camera: cameraReducer,
  setup: barnSetupReducer,
  horseList: horseListReducer,
  userList: userListReducer,
  clip: clipReducer,
  note: noteReducer
});