import {
  appColors,
  flexbox,
  fontbox,
  appFonts,
  borderRadius,
  padding,
  fullWidth,
  paddingXY,
} from '../../emotion/utils'

export const css = {
  noteArea: {
    ...flexbox('column', 'center', 'center', 8),
    ...fullWidth(),

    '& p': {
      ...fontbox(appFonts.inter, 26, 400, 24),
      color: appColors.grays[900],
      textAlign: 'center',
    },
  },

  noteBlock: {
    ...borderRadius(8),
    ...flexbox("column", "flex-start", "space-between", 8),
    ...fullWidth(),
    ...paddingXY(16),
    backgroundColor: appColors.grays[50],

    "> *": {
      ...fullWidth(),
    },
  },

  noteHeader: {
    ...flexbox("row", "center", "space-between", 8),
    ...fontbox(appFonts?.inter, 14, 400, 20),

    authorInfo: {
      color: appColors?.grays?.[900],
    },

    date: {
      color: appColors?.grays?.[400],
    },
  },

  noteName: {
    fontWeight: 700,
  },
  noteDate: {
    display: 'inline-flex',
    marginLeft: 'auto',
  },

  noteContent: {
    ...fontbox(appFonts?.inter, 14, 400, 14 * 1.5),
    color: "#383838",
  },
}
