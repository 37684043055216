import { display, height } from "@mui/system";
import { appColors } from "../../emotion/utils";

  export const css = {
    NavButtons: (theme) => ({
        display: "flex",
        li:{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            width: "94px",
            height: "60px",
            textAlign: "center",
            color: appColors.greens.textlight,
            fontSize: "18px",
            
            '&:hover': {
                cursor: "pointer",
            },

            '&:not(.logout, .active):hover': {
                color: appColors.greens.textdark,
                padding: "6px 0 0",
                backgroundColor: appColors.greens.hightight,
                //darken(theme.button.primary.background, -0.25),
                borderBottom: `6px solid ${appColors.reds.secondary}`,
            },


            '&.active': {
                color: appColors.greens.textdark,
                padding: "6px 0 0",
                backgroundColor: appColors.greens.navbuttonactivebg,
                borderBottom: `6px solid ${appColors.reds.secondary}`,
                
            },
        }
    }),
}