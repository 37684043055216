import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Hero from "./components/hero/hero";
import Footer from "./components/footer/footer";
import { useNavigate } from "react-router-dom";

const Marketing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Horsewise - Doing Best, Better";

    const user = window.localStorage.getItem('user');
    if (user) {
      console.log('user found..');
      navigate(
        "/admin/stalls"
      );
    }

  }, []);
  return (
    <>
      <Hero />
      <Footer />
    </>
  );
};

export default Marketing;
