/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'

import HorseCard from './horse-card'
import HorseSection from './horse-section'

import { css } from './css'
import Icon from '../../components/icon/icon'
import CustomDivTable from '../../components/custom-table/custom-div-table'
import { appColors } from '../../emotion/utils'
import { changeCurrentHorse } from "../../store/horseSlice";
import { changeCurrentCamera } from '../../store/cameraSlice'
import { useAppDispatch, useAppSelector } from "../../shared/model";

export default function StallSection(props) {
  const {
    data: stallData,
    horses = [],
    activeBtnGroup
  } = props;

  const dispatch = useAppDispatch();

  const [activeHorse, setActiveHorse] = useState(null);

  useEffect(() => {
    if (horses.length && !activeHorse) {
      setActiveHorse(horses[0]);
    }
  }, [horses, activeHorse]);

  const handleCardClick = data => {
    console.log('handle card click..', data);
    dispatch(changeCurrentHorse(data));
    if (data['camera'] && data['camera'][0]) {
      const firstCamera = data['camera'][0];
      console.log('first cmaera..', firstCamera);
      dispatch(changeCurrentCamera(firstCamera));
    }
    setActiveHorse(data);
  }

  const tableCols = [
    { label: 'name' },
  ];

  const horseData = horses?.sort((a, b) => a.name < b.name ? -1 : 1);

  const stallsBlock = activeBtnGroup === 'cards'
    ? horseData.map(datum => (
      <HorseCard
        key={datum.id}
        data={datum}
        stall={stallData}
        onClick={() => handleCardClick(datum)}
      />
    ))
    : (
      <CustomDivTable
        columns={tableCols}
        rows={horseData}
        count={horseData.length}
        onSelect={(r) => handleCardClick(r)}
      />
    );

  const trayBlock = (
    <div css={css.tray} className="open">
      <HorseSection data={activeHorse} />
    </div>
  );

  return (
    <div
      css={css.cardSection}
      className={`cardsection tray-open`}
    >
      {stallsBlock}
      {trayBlock}
    </div>
  )
}
