/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import {
  appFonts,
  borderRadius,
  darken,
  fontbox,
  paddingX,
  paddingY,
  transition,
} from "../../emotion/utils";
import { Button as PrButton } from "primereact/button";

const Button = (props) => {
  const type = props?.type || "button";
  const isLink = props?.link || false;
  const color = props?.color || "primary";

  const theme = useTheme();

  const cssContainer = {
    ...css(theme, color).container,
    ...(isLink && css(theme, color).container?.link),
  };

  return <PrButton unstyled type={type} css={cssContainer} {...props} />;
};

const css = (theme, color) => ({
  container: {
    ...borderRadius(8),
    ...fontbox(appFonts.inter, 16, 600, 24),
    ...paddingX(16),
    ...paddingY(10),
    ...transition(),
    backgroundColor:
      theme?.button?.[color]?.background || theme?.button?.primary?.background,
    border: `1px solid ${
      theme?.button?.[color]?.border || theme?.button?.primary?.border
    }`,
    color: theme?.button?.[color]?.color || theme?.button?.primary?.color,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: darken(theme?.button?.[color]?.background, -0.25),
    },

    link: {
      ...borderRadius(0),
      ...paddingX(0),
      ...paddingY(0),
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "unset",
      color: theme.button[color].border || theme.button.primary.border,
      flex: "unset",
      marginRight: "auto",
      width: "auto",

      "&:hover": {
        backgroundColor: "transparent",
        color: darken(theme.button[color].border, -0.25),
      },
    },
  },
});

export default Button;
