import {
  appColors,
  flexbox,
  fullWidth,
} from '../../emotion/utils'

export const css = {
  navActionBar: theme => ({
    ...flexbox('row', 'center', 'space-between', 12),
    ...fullWidth(),

    button: {
      width: 144,
    },    
  }),
  
  addhorsebtn: {
    color: appColors.greens.base,
    background: appColors.grays[0],
    ":hover": {
      background: appColors.greens.base,
      color: appColors.grays[0],
    },
  },
  
  tabButton: {
    "&.lt": {
      borderRadius: '8px 0 0 8px',
      width: '100px',
      marginRight: 0,
    },
    "&.rt": {
      borderRadius: '0 8px 8px 0',
      borderLeft: '0px',
      width: '100px',
    },
    "&.active": {
      color: appColors.grays[900],
      background: appColors.grays[100],
    },
    
  },
  
  actionGroup: {
    ...flexbox("row", "center", "flex-end", 24),
  },
}
