import React from "react";

import vector from "../../../../images/vector.svg";
import loginIcon from "../../../../images/login-icon.svg";

import css from "./hero.module.scss";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div
      className={css.heroSection}
    >
      <div className={css.nav}>
        <div className={css.links}>
          <Link to="/">
            <a className={css.logo}>
              <img className={css.vectorIcon1} alt="" src={vector} />
            </a>
          </Link>
          <Link to="login">
            <div className={css.loginButton}>
              <button className={css.logIn1}>
                <b className={css.logIn2}>Log In</b>
              </button>
              <img className={css.loginIcon} alt="" src={loginIcon} />
            </div>
          </Link>
        </div>
      </div>
      <div className={css.heroText} data-animate-on-scroll>
        <div className={css.text}>
          <b className={css.simplifyShowmanship}>Horsewise</b>
        </div>
      </div>
    </div>
  );
};

export default Hero;
