/** @jsxImportSource @emotion/react */

import { useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { Dialog } from "primereact/dialog";

import Client from "../../client";
import { appColors } from "../../emotion/utils";

import { css } from "../../components/modals/css";
import ModalButtonSet from "../../components/modal-set-button/modal-set-button";

export default function ShowArchiveHorseModal({
  setShowModal = () => {},
  horse,
  onSuccess = () => {},
}) {
  // console.log('horse is..', horse);

  const toastRef = useRef();

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "remove-horse",
    networkMode: "always",
    mutationFn: async () =>
      Client.patch(`/horses/${horse?.id}`, { archive: !horse?.archived_at }),
    onSuccess: () => {
      setShowModal(false);
      onSuccess();
    },
    onError: (error) => {
      console.error(error);
    }
      // toastRef.current.show({
      //   severity: "error",
      //   summary: "Error",
      //   detail: error.toString(),
      //   life: 3000,
      // }),

  });

  const handleCancel = () => {
    setShowModal(false)
  }

  return (
    <Dialog
      header={
        <span css={css.dialogHeader} className="dialog-header">
          {horse?.archived_at ? "Reactivate" : `Remove ${horse?.name || "horse"}`}
        </span>
      }
      visible={true}
      style={{ width: 400 }}
      onHide={handleCancel}
    >
      <p> Are you sure you want to {horse?.archived_at ? "reactivate" : "remove"} horse <i>{horse?.name}</i>?</p>
      <ModalButtonSet
        onCancel={handleCancel}
        isSubmitting={isLoading}
        onSubmit={submitRequest}
        submitButtonText={horse?.archived_at ? "Reactivate" : "Remove"}
        submitButtonColor={appColors?.reds?.destructive}
        disabled={isLoading}
      />
    </Dialog>
  );
}