/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import React, { useState, useEffect } from "react";
import Button from "../button/button";
import TabButton from "../tab-button/tab-button";
import { ButtonGroup } from 'primereact/buttongroup';

import AddUserModal from "../modals/AddUserModal";
import AddHorseModal from "../modals/AddHorseModal";
import { useLocation, useNavigate } from 'react-router-dom';
import { css } from "./css";

export default function NavigationActionBar(props) {
  const { refetch } = props;

  const [showAddHorseModal, setShowAddHorseModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  //const [activeTab, setActiveTab] = useState('users');
  const navigate = useNavigate();
  const location = useLocation();

  const onClickAddHorse = () => {
    setShowAddHorseModal(true);
  };

  const onClickAddUser = () => {
    setShowAddUserModal(true);
  };

  useEffect(() => {
    // Set the active tab based on the current path
    if (location.pathname.includes('/admin/users')) {
      setActiveTab('users');
    } else if (location.pathname.includes('/admin/horses')) {
      setActiveTab('horses');
    }
  }, [location.pathname]);

  const onClickUsers = () => {
    setActiveTab('users');
    navigate('/admin/users');
  };

  const onClickHorses = () => {
    setActiveTab('horses');
    navigate('/admin/horses');
  };

  const filterBtnGroup = (
    <ButtonGroup>
      <TabButton
        className={`lt ${activeTab === 'users' ? 'active' : ''}`}
        css={css.tabButton}
        onClick={onClickUsers}
      >Users</TabButton>

      <TabButton
        className={`rt ${activeTab === 'horses' ? 'active' : ''}`}
        css={css.tabButton}
        onClick={onClickHorses}
      >Horses</TabButton>
    </ButtonGroup>
  );

  const actionGroup = (
    <div css={css.actionGroup}>
      <Button
        css={css.addhorsebtn}
        onClick={onClickAddHorse}
      >
        Add Horse
      </Button>
      <Button
        onClick={onClickAddUser}
      >
        Add User
      </Button>
    </div>
  );

  const modals = (
    <>
      {showAddHorseModal && (
        <AddHorseModal
          setShowModal={setShowAddHorseModal}
          open={showAddHorseModal}
          onSuccess={() => {
            setShowAddHorseModal(false);
            refetch();
          }}
        />
      )}
      {showAddUserModal && (
        <AddUserModal
          setShowModal={setShowAddUserModal}
          open={showAddUserModal}
          onSuccess={() => {
            setShowAddUserModal(false);
            refetch();
          }}
        />
      )}
    </>
  );

  return (
    <>
      <div css={css.navActionBar}>
        {filterBtnGroup}
        {actionGroup}
        {modals}
      </div>
    </>
  );
}