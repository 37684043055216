import {
	appColors,
	appFonts,
  borderRadius,
	flexbox,
	fontbox,
	fullHeight,
	fullWidth,
	marginAuto,
	paddingX,
	paddingXY,
	paddingY,
	posAbsolute,
	posRelative,
	theme,
} from "../../emotion/utils";

export const css = {
  main: {
    ...flexbox('column', 'center', 'flex-start'),
    ...fullHeight(),
    backgroundColor: theme.colors.bgSecondary,
    paddingTop: 65,
  },
  button: {
    ...fontbox(appFonts.inter, 14, 700, 24),
    ...fullWidth(),
    //backgroundColor: appColors.grays[0],
    //color: appColors.grays[900],
    '&:hover': {
      color: appColors.grays[0],
    },
  },
  form: {
    ...fullWidth(),

    textarea: {
      marginBottom: 16,
    },
  },
}