import {
	appColors,
	appFonts,
  borderRadius,
	flexbox,
	fontbox,
	fullHeight,
	fullWidth,
	marginAuto,
	paddingX,
	paddingXY,
	paddingY,
	posAbsolute,
	posRelative,
	theme,
    margin,
} from "../../emotion/utils";

export const css = {
    container: {
       
        label: {
            ...fontbox(appFonts.inter, 14, 700, 24),
            color: appColors.grays[700],
            marginBottom: 8,
        },
        
        input: {
            ...fontbox(appFonts.inter, 14, 400, 24),
            ...borderRadius(4),
            border: "1px solid #E6E9EA" ,
            color: appColors.grays[900],
            width: "100%",
            padding: "12px 16px",
            marginBottom: 24,
            '&:focus-visible': {
                borderColor: appColors.grays[900],
            },
        },
        select: {
            ...fontbox(appFonts.inter, 14, 400, 24),
            ...borderRadius(4),
            border: "1px solid #E6E9EA" ,
            color: appColors.grays[900],
            width: "100%",
            padding: "12px 16px",
            marginBottom: 24,
            '&:focus-visible': {
                borderColor: appColors.grays[900],
            },
        },
    },
    dialogHeader: {
        ...fontbox(appFonts.inter, 18, 700, 16),
    },


};