/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { css } from './css'
import { appColors } from '../../emotion/utils'
import { Tooltip } from "primereact/tooltip";

import IconTooltip from "../icons/icon-tooltip";
import FormInput from "../form-input/form-input";
import FormTextarea from "../form-textarea/form-textarea";

export default function FormTextInput(props) {
  const {
    formik,
    testChange,
    readOnly,
    name,
    label,
    textarea,
    number,
    labelPosition,
    tooltipText,
    color,
    size,
    viewBox,
    icon,
    hidden,
    value,
    required,
    placeholder,
    onBlur,
    onFocus,
    type="text",
  } = props;

  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const width = "100%";

  const inputEl = textarea ? (
    <div style={{ width }}>
      <FormTextarea
        formik={formik}
        name={name}
        onInput={testChange}
        value={formik?.values[name] || value || ""}
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </div>
  ) : (
    <div style={{ width }}>
      <FormInput
        formik={formik}
        name={name}
        number={number}
        type={type}
        onInput={(evt) => {
          if (testChange) {
            testChange(evt, name);
          }
        }}
        readOnly={readOnly}
        style={{ width }}
        value={formik?.values[name] || value || (number ? 0 : "")}
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </div>
  );

  return (
    <div css={css.container}
      hidden={hidden}
      className={`form-group-item ${
        labelPosition === "top" ? "label-top" : ""
      }`}
    >
      {(label || icon) && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <label htmlFor={name}>
              {label} {required && <span style={{ color: '#A00D06' }}>*</span>}
            </label>
          {icon && (
            <span className="custom-icon">
              <IconTooltip
                id={`${name}-icon`}
                tooltipText={tooltipText}
                color={color}
                size={size}
                icon={icon}
                viewBox={viewBox}
              />
            </span>
          )}
        </div>
      )}
      {inputEl}
    </div>
  );
}