/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { Toast } from "primereact/toast";
import { useQuery } from "../../hooks";
import { ENDPOINT } from "../../client";
import StallSection from "./stall-section";
import NavigationActionBar from "../../components/navigation-action-bar/navigation-action-bar";
import { useAppDispatch, useAppSelector } from '../../shared/model';
import { changeCurrentHorse } from "../../store/horseSlice";
import { changeCurrentCamera } from "../../store/cameraSlice";

import FilterBar from './filter-bar';

function StallHorses() {
  const dispatch = useAppDispatch();

  const { id: stallId } = useParams();

  const toastRef = useRef();

  const [search, setSearch] = useState('');

  const [activeBtnGroup, setActiveBtnGroup] = useState("cards");

  const { data, refetch } = useQuery(`/stall/${stallId}`);

  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      {/*<FilterBar
        search={search}
        setSearch={setSearch}
        activeBtnGroup={activeBtnGroup}
        setActiveBtnGroup={setActiveBtnGroup}
      /> */}
      {/* <div className="container"> */}
      <StallSection data={data} horses={data?.horse} activeBtnGroup={activeBtnGroup} />
      {/* </div> */}
    </>
  );
}

export default StallHorses;