/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Toast } from "primereact/toast";
import { useQuery } from "../../hooks";
import { ENDPOINT } from "../../client";
import StallSection from "./stall-section";
import NavigationActionBar from "../../components/navigation-action-bar/navigation-action-bar";
import { useAppDispatch, useAppSelector } from '../../shared/model';
import { changeCurrentHorse } from "../../store/horseSlice";
import { changeCurrentCamera } from "../../store/cameraSlice";

import FilterBar from './filter-bar';

function Stalls() {
  const dispatch = useAppDispatch();

  const toastRef = useRef();

  const [search, setSearch] = useState('');

  const [activeBtnGroup, setActiveBtnGroup] = useState("cards");

  const { data, refetch } = useQuery(
    `/stalls`,
  );

  const searchRegex = new RegExp(`^${search}`, 'i');

  const filteredData = data?.data
    .filter((stall) => {
      if (!search) {
        return true;
      }

      if (searchRegex.test(stall.name)) {
        return true;
      }

      return stall.horse.some(h => searchRegex.test(h.name));
    });

  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      {/*<FilterBar
        search={search}
        setSearch={setSearch}
        activeBtnGroup={activeBtnGroup}
        setActiveBtnGroup={setActiveBtnGroup}
      /> */}
      <div className="container">
        <StallSection data={filteredData} activeBtnGroup={activeBtnGroup} />
      </div>
    </>
  );
}

export default Stalls;