import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as Horse } from "./assets/horse.svg";
import { ReactComponent as Settings } from "./assets/settings.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";
import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as Video } from "./assets/video.svg";
import { ReactComponent as X } from "./assets/x.svg";

export const icons = {
  ChevronRight,
  Edit,
  Horse,
  Settings,
  Trash,
  User,
  Video,
  X,
};
