import { theme } from 'antd'
import {
  appColors,
  flexbox,
  fontbox,
  appFonts,
  padding,
} from '../../emotion/utils'
export const css = {
  videocard: theme => ({
    ...flexbox('row', 'center', 'center'),
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    // height: "604px",
    gap: '16px',
    backgroundColor: appColors.grays[0],
    borderRadius: 8,
    padding: '16px',
    marginBottom: 16,
    '& p': {
      ...fontbox(appFonts.inter, 26, 400, 24),
      color: appColors.grays[900],
      textAlign: 'center',
    },
  }),
  videoplayer: theme => ({
    width: '70%',
    height: '100%',
  }),
  notes: theme => ({
    backgroundColor: appColors.grays[0],
    width: '30%',
    // padding: "16px",
    height: '100%',
    h2: {
      ...fontbox(appFonts.inter, 14, 700, 24),
      color: appColors.grays[900],
      textAlign: 'left',
      marginBottom: '8px',
    },
  }),
  actionsarea: theme => ({
    ...flexbox('column', 'center', 'center'),
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    gap: '12px',
    backgroundColor: appColors.grays[0],
    borderRadius: 8,
    marginBottom: 16,
    div: {
      width: '100%',
    },
  }),
  savedclips: theme => ({
    backgroundColor: '#F6F6F6',
    width: '98%',
    padding: '16px',
    borderRadius: 8,
    margin: 16,
    h2: {
      ...fontbox(appFonts.inter, 18, 500, 24),
      color: appColors.grays[900],
      textAlign: 'left',
      paddingLeft: '16px',
      marginBottom: '8px',
    },
  }),
  clips: theme => ({
    ...flexbox('row', 'center', 'center'),
    flexWrap: 'wrap',
    
  }),
}
