/** @jsxImportSource @emotion/react */

import { css } from "./css";
import { useNavigate } from "react-router-dom";
import { changeClipFile } from "../../../../store/viewerSlice";
import { useAppDispatch, useAppSelector } from "../../../../shared/model";

export default function ClipCard(props) {

  const dispatch = useAppDispatch();

  const getFirstImage = (images) => {
    const placeholderImage = 'https://placehold.co/600x400?text=Horse\nWise';
    if (images && images.length > 0) {
      const firstImage = images[0];
      return firstImage.filename;
    }
    return placeholderImage;
  };

  const { data } = props;

  const {
    name,
    stall,
    imageUrl,
    id
  } = data;

  const onClipClick = () => {
    console.log('on click clip..', data);
    dispatch(changeClipFile(data));
  };

  return (
    <>
      <div onClick={onClipClick} css={css.clipCard} className="clipcard">
        <div css={css.cardHeader}>
          <div>
            <h1>{name}</h1>
          </div>
          <div>
            <p></p>
          </div>
        </div>
        <div>
          <img src={imageUrl} />
        </div>
      </div>
    </>
  );
}