import {
	appColors,
	appFonts,
  borderRadius,
	flexbox,
	fontbox,
	fullHeight,
	fullWidth,
	marginAuto,
	paddingX,
	paddingXY,
	paddingY,
	posAbsolute,
	posRelative,
	theme,
    margin,
} from "../../emotion/utils";

export const css = {
  save: {
        ...fontbox(appFonts.inter, 14, 700, 24),
        width: 172,
        justifyContent: "center",
        borderRadius: 8,
        border: "none",
		backgroundColor: theme.colors.bgSecondary,
        '&:hover': {
			color: appColors.greens.textlight,
            backgroundColor: appColors.grays[100],
		}
	},
	cancel: {
		...fontbox(appFonts.inter, 14, 700, 24),
        width: 172,
        justifyContent: "center",
        borderRadius: 8,
        border: "1px solid " + appColors.grays.border,
		backgroundColor: appColors.grays[0],
		color: appColors.grays[900],
		'&:hover': {
			color: appColors.greens.base,
            backgroundColor: appColors.grays[100],
		}
	},
	modalButtons: {
        ...flexbox("row", "center", "center", 8),
        ...margin(32, 0),
	},
};