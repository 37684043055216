import { appColors, flexbox, fontbox, appFonts, borderRadius, fullWidth, square } from '../../emotion/utils'

export const css = {
  videocard: {
    ...borderRadius(8),
    ...flexbox('column', 'center', 'center'),
    ...fullWidth(),
    backgroundColor: appColors?.grays?.[50],
    borderRadius: 8,
    border: `1px solid ${appColors?.grays?.[100]}`,
    overflow: "hidden",
   
    '& p': {
      ...fontbox(appFonts.inter, 16, 500, 20),
      color: "#9F9F9F",
      letterSpacing: "0.15em",
      textAlign: 'center',
    },

    "> div": {
      ...borderRadius(7),
    },

    loading: {
      ...flexbox("column", "center", "center"),
      minHeight: 300,

      "svg": {
        ...square(120),
      }
    },
  },
}
