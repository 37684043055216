/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react'
import { Tag } from 'antd'

import { useQuery } from '../../hooks/use-query'
import { useAppDispatch, useAppSelector } from '../../shared/model'
import { ENDPOINT } from '../../client'

import { changeCurrentCamera } from '../../store/cameraSlice'

import PillButton from '../pill-button/pill-button'
import StallSettingsModal from '../modals/StallSettingsModal'
import { changeCurrentHorse, selectedCurrentHorse } from "../../store/horseSlice";

import Icon from '../icon/icon'

import { css } from './css';

export default function CameraSelectorComponent(props) {
  const dispatch = useAppDispatch()
  const [activeCameraId, setActiveCameraId] = useState(null)
  const [cameras, setCameras] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { cameraData } = props
  const currentHorse = useAppSelector(selectedCurrentHorse)

  const onClickTag = buttonId => {
    setActiveCameraId(buttonId)
    console.log('on clicking tag..')
  }

  const handleCogClick = () => {
    console.log('cog clicked..')
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const camerasUri = useMemo(() => {
    const url = new URL('/cameras', ENDPOINT);
    url.searchParams.append('horse_id', currentHorse?.id);

    return url.toString().replace(ENDPOINT, '');
  }, [currentHorse]);

  const { data, refetch } = useQuery(camerasUri)

  useEffect(() => {
    if (data) {
      setCameras(data)
    }
  }, [data])

  useEffect(() => {
    if (cameras.length > 0 && !activeCameraId) {
      setActiveCameraId(cameras[0].id)
      dispatch(changeCurrentCamera(cameras[0]))
    }
  }, [cameras, activeCameraId, dispatch])

  const handleCameraClick = camera => {
    console.log('camera id is..', camera.id)
    setActiveCameraId(camera.id)
    dispatch(changeCurrentCamera(camera))
  }

  const camerasList = cameras?.map((item, key) => (
    <PillButton
      key={key}
      onClick={() => handleCameraClick(item)}
      className={activeCameraId === item.id ? 'active' : ''}
    >
      {item.name}
    </PillButton>
  ));

  const settingsBtn = (
    <div css={css.settings} onClick={handleCogClick}>
      <Icon icon="Settings" stroke="#475467" />
    </div>
  );

  const cameraArea = (
    <div css={css.cameraArea} className="camera-area">
      {camerasList}      
      {settingsBtn}

      {cameraData?.map((col, index) => (
        <Tag key={index} onClick={() => onClickTag(col.id)}>
          {col.name}
        </Tag>
      ))}
    </div>
  );

  const modals = (
    <>
      {isModalOpen && (
        <StallSettingsModal
          open={isModalOpen}
          setShowModal={setIsModalOpen}
          onClose={handleModalClose}
        />
      )}
    </>
  );

  return (
    <>
      {cameraArea}
      {modals}
    </>
  )
}
