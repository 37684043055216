export default function Tags(props) {
  const data = [
    { label: 'hot', color: 'red' },
    { label: 'warm', color: 'green' },
    { label: 'cold', color: 'blue' },
  ];

  const tagEls = data.map((t) => (
    <div key={t.label} style={{ backgroundColor: t.color, padding: '4px', borderRadius: '4px', color: 'white' }}>
      <span>{t.label}</span>
    </div>
  ));

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
      {tagEls}
    </div>
  );
}
