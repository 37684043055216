/** @jsxImportSource @emotion/react */

import { css } from './css'
import React from 'react'
import { useField } from 'formik'
import { RadioButton } from 'primereact/radiobutton'

const FormRadioButton = ({ label, groupLabel, ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <>
      {groupLabel && <div css={css.groupLabel}>{groupLabel}</div>}
      <div css={css.container}>
        <RadioButton
          inputId={props.id || props.name}
          {...field}
          {...props}
          checked={field.value === props.value}
          onChange={() => helpers.setValue(props.value)}
        />
        <label htmlFor={props.id || props.name}>{label}</label>
        {meta.touched && meta.error ? (
          <div className="p-error">{meta.error}</div>
        ) : null}
      </div>
    </>
  )
}

export default FormRadioButton
